<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import ListingTable from "@/components/listings/listing-table";

export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        ListingTable,
    },
    data() {
        return {
            title: "Role",
            items: [
                {
                    text: "Role"
                },
            ],
            fields: [
                { key: "id", sortable: true, label: 'ID' },
                { key: 'role', sortable: true, label: 'Role' },
                { key: 'display_name', sortable: true, label: 'Name' },
                { key: "action" },
            ]
        };
    },
    computed: {

    },
    watch: {

    },
    async mounted() {
        
    },
    methods: {
        deleteRecord(id) {
            const deleteCallback = async () => {
                const result = await axios.delete(`${process.env.VUE_APP_APIURL}/setting/role/${id}`, {
                    headers: authHeader(),
                });
                if (result.data.code === 'success') {
                    this.filterTable();
                    this.$refs.confirmationModal.closeModal();
                    this.$refs.messageModal.showModal('The record has been deleted');
                } else if (result.data.code === 'permission_denied') {
                    this.$refs.confirmationModal.closeModal();
                    this.$refs.messageModal.showModal('Permission Denied');
                }
            }
            this.$refs.confirmationModal.showModal(`Confirm delete role (ID: ${id})?`, deleteCallback);
        },

        setAdminView() {
            // this.filter = Object.assign(this.filter, filter);
        },

        filterTable() {
            this.$refs.listingTable.filterTable('', '');
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="border-radius: 15px;">
                    <div class="card-body pt-0">
                        <div class="row mt-3 ml-1">
                            <router-link class="btn btn-add" :to="{name: 'Role Form', params: {id: 'add'}}"><i class="mdi mdi-plus-thick"></i> Add New Role</router-link>
                        </div>
                    </div>
                    <div class="pl-4 pr-4">
                        <ListingTable ref="listingTable" :fields="fields" :setAdminView="setAdminView" path="setting/role" listName="roles">
                            <template v-slot:cell(action)="{ item }">
                                <router-link class="mr-3 grey-icon-color" :to="{name: 'Role Form', params: {id: item.id}}"><i class="ri-pencil-line font-size-18" v-b-tooltip.hover title="Edit"></i></router-link>
                                <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
                                    @click="deleteRecord(item.id)">
                                    <i class="ri-delete-bin-line font-size-18"></i>
                                </a>
                            </template>
                        </ListingTable>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>